import { useMemo } from "react";
import { TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const StopDateTimeFields = (props: {
  dateFieldLabel: string;
  timeFieldLabel: string;
  value: DateTime;
  onChange: (newDate: DateTime) => void;
  timeError?: boolean;
}) => {
  const { t } = useTranslation("addDropoffPage");

  const timeAsString = useMemo(
    () => props.value.toFormat("HH:mm"),
    [props.value]
  );

  return (
    <div className="flex flex-col gap-1">
      <TextField
        fullWidth
        type="date"
        margin="dense"
        label={props.dateFieldLabel}
        value={props.value.toISODate()}
        onChange={(e) => {
          const parsedDate = DateTime.fromISO(e.target.value);
          props.onChange(
            props.value.set({
              year: parsedDate.get("year"),
              month: parsedDate.get("month"),
              day: parsedDate.get("day"),
            })
          );
        }}
      />
      <TextField
        fullWidth
        margin="dense"
        type="time"
        label={props.timeFieldLabel}
        value={timeAsString}
        onChange={(e) => {
          const time = DateTime.fromFormat(e.target.value, "HH:mm");
          props.onChange(
            props.value.set({
              hour: time.get("hour"),
              minute: time.get("minute"),
            })
          );
        }}
      />
      {props.timeError && (
        <div style={{ color: "red", marginBottom: 8 }}>
          {t("pickupDropoffTimeError")}
        </div>
      )}
    </div>
  );
};

export default StopDateTimeFields;
