import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "./hooks";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useRouteLogin } from "../../ducks/app/route-login/hooks";
import { Route } from "../../ducks/app/route-login";
import { Me } from "../../ducks/auth";
import withAuth from "../../components/withAuth";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

interface Trailer {
  id: string;
  label: string;
}

type RouteOption = Route | string;

export const formatCustomDate = (date: Date, toFormat: string) => {
  return DateTime.fromJSDate(date).toFormat(toFormat);
};

export const formatCustomDateString = (
  dateString: string,
  toFormat: string
) => {
  return formatCustomDate(new Date(dateString), toFormat);
};

const getRouteName = (r: Route) => {
  const routeName =
    r.routeName ||
    (r.firstStop.city &&
      r.lastStop.city &&
      `${r.firstStop.city} - ${r.lastStop.city}`) ||
    "";
  const date = r.destinationDate || r.createdAt;
  const destinationDate = date ? formatCustomDateString(date, "dd/MM") : "";
  return `${routeName} ${destinationDate && `(${destinationDate})`}`;
};

export const SelectTrailerPage = ({
  me,
  showCompletedTrips,
}: {
  me: Me;
  showCompletedTrips?: boolean;
}) => {
  const { status, trailers, routes, loginRoute } = useRouteLogin({
    showCompletedTrips,
  });
  const { t } = useTranslation("authPage");
  const options = useMemo(
    () => trailers.map((t) => ({ id: t.id, label: t.number })),
    [trailers]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [trailer, setTrailer] = useState<Trailer | null>(null);
  const [route, setRoute] = useState<RouteOption | null>(null);
  const [routeOptions, setRouteOptions] = useState<RouteOption[]>([]);

  useEffect(() => {
    if (!trailer && options) {
      setTrailer(options.find((x) => x.id === me?.defaultTrailerId) || null);
    }
  }, [trailer, options, me]);
  useEffect(() => {
    if (!route && routes) {
      setRoute(
        routes.find(
          (x) => x.trailerId && x.trailerId === me?.defaultTrailerId
        ) || null
      );
    }
  }, [route, routes, me]);
  useMemo(() => {
    const sortedRoutes = [...routes].sort((a, b) => {
      const dateA = a.destinationDate
        ? new Date(a.destinationDate).getTime()
        : Infinity;
      const dateB = b.destinationDate
        ? new Date(b.destinationDate).getTime()
        : Infinity;
      return dateA - dateB;
    });
    const concatRouteOptions = me.canAddRoutes
      ? [...sortedRoutes, "NEW_ROUTE"]
      : sortedRoutes;
    setRouteOptions(concatRouteOptions);
  }, [routes, me.canAddRoutes]);

  if (me.sessionId) {
    const q = new URLSearchParams(location.search);
    if (!q.get("force")) {
      navigate(`/sessions/${me.sessionId}`);
      return null;
    }
  }
  const onChange = (_e: unknown, value: Trailer | null) => {
    setTrailer(value);
  };
  const onRouteChange = (_e: unknown, value: RouteOption | null) => {
    setRoute(value);
  };
  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (route && trailer) {
      const routeId = typeof route === "string" ? null : route.id;
      const { sessionId } = await loginRoute({
        routeId,
        trailerId: trailer.id,
      });
      navigate(`/sessions/${sessionId}`);
    }
  };

  switch (status) {
    case "UNINITIALIZED":
      return <p>{t("selectVehicle.loading")}</p>;
    case "LOAD_ERROR":
      return <p>{t("selectVehicle.errorLoading")}</p>;
    case "READY": {
      return (
        <Container component="main" maxWidth="xs">
          <Box
            mt={14}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "secondary.main",
                alignSelf: "center",
              }}
            >
              <LocalShippingIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ alignSelf: "center" }}
            >
              {showCompletedTrips
                ? t("selectVehicle.headingCompletedTrips")
                : t("selectVehicle.heading")}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={onSubmit}>
              <Autocomplete
                disablePortal
                openOnFocus
                options={routeOptions}
                value={route}
                onChange={onRouteChange}
                getOptionLabel={(option) =>
                  typeof option === "string"
                    ? t("newRoute")
                    : getRouteName(option)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    color="primary"
                    margin="normal"
                    required
                    fullWidth
                    label={t("selectVehicle.textFieldLabel_TRIP")}
                    name="route"
                    autoComplete="route"
                  />
                )}
              />
              <Autocomplete
                disablePortal
                options={options}
                value={trailer}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    required
                    fullWidth
                    label={t("selectVehicle.textFieldLabel_VEHICLE")}
                    name="trailer"
                    autoComplete="trailer"
                  />
                )}
              />
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("selectVehicle.selectButtonLabel")}
              </Button>
            </Box>
          </Box>
        </Container>
      );
    }
  }
};

export default withAuth(SelectTrailerPage);
