import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux-store";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Divider,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import List from "@mui/material/List";
import {
  selectCargosCreatedByDispatcher,
  selectCargosCreatedByDriver,
  selectIsStarted,
  selectIsStopped,
  selectShouldDisplayHideShowCompletedStopsButton,
  selectShowCompletedStops,
  selectStopsAndWaypointsNumber,
} from "../../ducks/app/session/selectors";
import {
  loadSession,
  startTrip,
  stopTrip,
  setShowCompletedStops,
} from "../../ducks/app/session";
import RouteStop from "./RouteStop";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import CheckIcon from "@mui/icons-material/Check";
import debounce from "lodash/debounce";
import partition from "lodash/partition";
import WaypointStop from "./WaypointStop";
import { selectStopsAndWaypointsFilteredByShouldShowCompleted } from "../../ducks/app/session/selectors";
import { RouteStop as RouteStopI, Stop } from "../../ducks/app/session/types";

const StartStopDialog = ({
  open,
  type,
  onCancel,
  onSubmit,
}: {
  open: boolean;
  type: "START" | "STOP";
  onCancel: () => void;
  onSubmit: (v: { odometer: number; note: string }) => Promise<any>;
}) => {
  const { t } = useTranslation("translation");
  const [odometer, setOdometer] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const submitHandler = useMemo(
    () =>
      debounce(
        () => {
          setLoading(true);
          onSubmit({ odometer: Math.round(+odometer), note }).catch((err) => {
            setLoading(false);
          });
        },
        1000,
        { leading: true, trailing: false }
      ),
    [setLoading, onSubmit, odometer, note]
  );
  const context = { context: type };
  return (
    <Dialog open={open} onClose={onCancel} fullWidth>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <DialogTitle>{t("startStopDialog.dialogHeading")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("startStopDialog.dialogContent", context)}
          </DialogContentText>
          <TextField
            autoFocus
            label="km"
            type="number"
            fullWidth
            sx={{ mt: 2 }}
            value={odometer}
            onChange={(e) => {
              setOdometer(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label={t("startStopDialog.noteFieldLabel")}
            sx={{ mt: 2 }}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </DialogContent>
        <Stack>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            size="large"
            sx={{ mx: 3 }}
          >
            {t("startStopDialog.submitButtonText", context)}
          </LoadingButton>
          <Button sx={{ my: 2 }} onClick={onCancel}>
            {t("startStopDialog.cancelButtonText")}
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
};

const StartDialog = ({
  sessionId,
  onClose,
}: {
  sessionId: string;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(
    (data: { odometer: number; note: string }) => {
      return dispatch(startTrip({ ...data, sessionId }))
        .unwrap()
        .then(() => {
          onClose();
        });
    },
    [dispatch, onClose, sessionId]
  );
  return (
    <StartStopDialog
      open={true}
      type="START"
      onCancel={onClose}
      onSubmit={onSubmit}
    />
  );
};
const StopDialog = ({
  sessionId,
  onClose,
}: {
  sessionId: string;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    (data: { odometer: number; note: string }) => {
      return dispatch(stopTrip({ ...data, sessionId }))
        .unwrap()
        .then(() => {
          navigate("/routes/login?force=true");
        });
    },
    [dispatch, navigate, sessionId]
  );
  return (
    <StartStopDialog
      open={true}
      type="STOP"
      onCancel={onClose}
      onSubmit={onSubmit}
    />
  );
};

const SessionPage = ({ sessionId }: { sessionId: string }) => {
  const { t } = useTranslation();
  // const stopIds = useSelector(selectStopIds(sessionId || ""));
  const routeStopsFilteredByShouldShowCompleted = useSelector(
    selectStopsAndWaypointsFilteredByShouldShowCompleted
  );
  const allRelevantRouteStopsNumber = useSelector(
    selectStopsAndWaypointsNumber
  );
  const dispatch = useAppDispatch();

  // TODO: temporary until a different solution (websockets?) is implemented
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadSession(sessionId));
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch, sessionId]);

  return (
    <>
      <Typography variant="h5" component="h1">
        {t("MyRoute")}
      </Typography>
      <Typography variant="overline">
        {t("AmountOfStops", {
          count: allRelevantRouteStopsNumber,
        })}
      </Typography>
      {routeStopsFilteredByShouldShowCompleted ? (
        <RouteStops
          routeStops={routeStopsFilteredByShouldShowCompleted}
          sessionId={sessionId}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const RouteStops = ({
  routeStops,
  sessionId,
}: {
  routeStops: RouteStopI[];
  sessionId: string;
}) => {
  const dispatch = useAppDispatch();
  const isStarted = useSelector(selectIsStarted(sessionId));
  const isStopped = useSelector(selectIsStopped(sessionId));
  const cargosCreatedByDispatcher = useSelector(
    selectCargosCreatedByDispatcher
  );
  const cargosCreatedByDriver = useSelector(selectCargosCreatedByDriver);
  const { t } = useTranslation("translation");
  const [dialogType, setDialogType] = useState<"START" | "STOP" | null>(null);
  const startTrip = () => setDialogType("START");
  const stopTrip = () => setDialogType("STOP");
  const clearDialog = useCallback(() => setDialogType(null), [setDialogType]);

  useEffect(() => {
    return () => {
      dispatch(setShowCompletedStops(false));
    };
  }, [dispatch]);

  let [stopsFromRoutesCreatedByDispatcher, stopsFromRoutesCreatedByDrivers] =
    useMemo(
      () =>
        partition(routeStops, (stop) => {
          if (stop.type === "CARGO_STOP") {
            return cargosCreatedByDispatcher.find(
              (cargo) => cargo.id === stop.stop.cargo_id
            );
          } else {
            return true;
          }
        }),
      [cargosCreatedByDispatcher, routeStops]
    );

  const stopsCreatedByDriver = useMemo(() => {
    let stops: RouteStopI[] = [];
    for (const cargo of cargosCreatedByDriver) {
      const stopsForCargo: RouteStopI[] =
        stopsFromRoutesCreatedByDrivers.filter(
          // only stops, no waypoints here
          (stop) => (stop as { stop: Stop }).stop.cargo_id === cargo.id
        );
      stops.push(...stopsForCargo);
    }
    return stops;
  }, [cargosCreatedByDriver, stopsFromRoutesCreatedByDrivers]);

  const showCompletedStops = useSelector(selectShowCompletedStops);

  const showHideCompletedStopsButton = useSelector(
    selectShouldDisplayHideShowCompletedStopsButton
  );

  return (
    <>
      {sessionId && dialogType === "START" ? (
        <StartDialog sessionId={sessionId} onClose={clearDialog} />
      ) : null}
      {sessionId && dialogType === "STOP" ? (
        <StopDialog sessionId={sessionId} onClose={clearDialog} />
      ) : null}
      <List sx={{ bgColor: "background.paper" }}>
        <ListItem sx={{ px: 0 }}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<PlayCircleOutlinedIcon />}
            onClick={startTrip}
            size="large"
            disabled={isStarted}
          >
            {!isStarted && t("startStopDialog.submitButtonText_START")}
            {isStarted && (
              <>
                {t("startStopDialog.submitButtonText_STARTED")} <CheckIcon />
              </>
            )}
          </Button>
        </ListItem>
        {showHideCompletedStopsButton && (
          <ListItem sx={{ px: 0 }}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                dispatch(setShowCompletedStops(!showCompletedStops));
              }}
            >
              {showCompletedStops
                ? t("startStopDialog.hideCompletedStops")
                : t("startStopDialog.showCompletedStops")}
            </Button>
          </ListItem>
        )}
        {stopsFromRoutesCreatedByDispatcher.map((routeStop, index) => (
          <Fragment key={index}>
            {routeStop.type === "CARGO_STOP" ? (
              <RouteStop stop={routeStop.stop} />
            ) : (
              <WaypointStop waypoint={routeStop} />
            )}
            {index < routeStops.length - 1 ? (
              <Divider light variant="inset" component="li" />
            ) : null}
          </Fragment>
        ))}
        <br />
        <br />
        {stopsCreatedByDriver.map((routeStop, index) => (
          <Fragment key={index}>
            {routeStop.type === "CARGO_STOP" ? (
              <RouteStop stop={routeStop.stop} />
            ) : (
              <WaypointStop waypoint={routeStop} />
            )}
            {index < routeStops.length - 1 ? (
              <Divider light variant="inset" component="li" />
            ) : null}
          </Fragment>
        ))}
        {isStarted && (
          <ListItem sx={{ px: 0 }}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<SportsScoreOutlinedIcon />}
              onClick={stopTrip}
              size="large"
              disabled={isStopped}
            >
              {!isStopped && (
                <span>{t("startStopDialog.submitButtonText_STOP")}</span>
              )}
              {isStopped && (
                <>
                  <span>{t("startStopDialog.submitButtonText_STOPPED")}</span>{" "}
                  <CheckIcon />
                </>
              )}
            </Button>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default SessionPage;
