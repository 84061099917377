import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { Stop } from "../../ducks/app/session/types";
import { useNavigate } from "react-router";
import { addInvoiceReference, loadSession } from "../../ducks/app/session";
import { useAppDispatch } from "../../redux-store";

const AddRefPage = (props: { sessionId: string; stop: Stop }) => {
  const { sessionId, stop } = props;
  const [ref, setRef] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("stopPage");
  const navigate = useNavigate();
  const onCancel = () => {
    navigate(-1);
  };
  const onAddClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        addInvoiceReference({
          sessionId,
          cargoId: stop.cargo_id,
          stopId: stop.id,
          ref,
        })
      );
      dispatch(loadSession(sessionId));
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography variant="h6" component="h2" pt={2}>
        {t("ref.heading")}
      </Typography>
      <Box component="form" mt={2} onSubmit={onAddClick}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              type="text"
              label={t("ref.textFieldLabel")}
              placeholder="ABC123"
              onChange={(e) => {
                setRef(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              size="large"
              fullWidth
              variant="contained"
            >
              {t("ref.addButtonLabel")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={onCancel}
            >
              {t("ref.backButtonLabel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddRefPage;
