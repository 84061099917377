import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RouteRoundedIcon from "@mui/icons-material/RouteRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AppBar from "@mui/material/AppBar";
import ErrorMessages from "../components/ErrorMessages";
import { useSelector } from "react-redux";
import { selectCurrentSessionId } from "../ducks/app/session/selectors";
import { useTranslation } from "react-i18next";
import { selectIsStopped } from "../ducks/app/session/selectors";
import { selectIsOutdated } from "../ducks/app/version/selectors";
import { Alert, AlertTitle, Button } from "@mui/material";

export default function Footer() {
  const [value, setValue] = React.useState(0);
  const currentSessionId = useSelector(selectCurrentSessionId);
  const isStopped = useSelector(selectIsStopped(currentSessionId));
  const { t } = useTranslation("translation");

  return (
    <Box sx={{ pb: 7 }}>
      <AppBar
        position="fixed"
        sx={{ top: "auto", bottom: 0, left: 0, right: 0 }}
      >
        <ErrorMessages />
        {currentSessionId && (
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              component={Link}
              to={`/sessions/${currentSessionId}`}
              label={t("footer.menuItem_TRIP")}
              icon={<RouteRoundedIcon />}
            />
            {!isStopped && (
              <BottomNavigationAction
                component={Link}
                to={`/sessions/${currentSessionId}/cargos/add`}
                label={t("footer.menuItem_CARGO")}
                icon={<AddCircleOutlineRoundedIcon />}
              />
            )}
          </BottomNavigation>
        )}
        <VersionOutdated />
      </AppBar>
    </Box>
  );
}

const VersionOutdated = () => {
  const isOutdated = useSelector(selectIsOutdated);
  const { t } = useTranslation("translation");
  if (!isOutdated) {
    return null;
  }
  const reloadClick = () => {
    window.location.reload();
  };
  return (
    <Alert
      severity="warning"
      action={
        <Button color="inherit" variant="outlined" onClick={reloadClick}>
          {t("footer.applicationOutdated.buttonText")}
        </Button>
      }
    >
      <AlertTitle>{t("footer.applicationOutdated.heading")}</AlertTitle>
      {t("footer.applicationOutdated.bodyText")}
    </Alert>
  );
};
