import React from "react";
import * as versionActions from "./ducks/app/version";
import { serializeError } from "serialize-error";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Settings as LuxonSettings } from "luxon";
import * as Sentry from "@sentry/react";
import { reportErrorToBackend } from "./ducks/api";
import { store } from "./redux-store";
import { replayIntegration } from "@sentry/react";
import { loadApiKeys } from "./ducks/app/api-keys";
// import { useLocation } from "react-router-dom";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useNavigationType,
// } from "react-router";

LuxonSettings.throwOnInvalid = true;
declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

Sentry.init({
  release: "test-release",
  dsn: "https://ade179d0fa07ad6b5c3fbb92337cde8f@o4507542742368256.ingest.de.sentry.io/4507542785425488",
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app.nordic\.dk/],
  integrations: [
    // Sentry.reactRouterV6BrowserTracingIntegration({
    //   useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
    replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.6, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

export const formatError = (err: any) =>
  err instanceof Error
    ? `${err.toString()}\n${err.message}\n${err.stack}`
    : JSON.stringify(err);

window.addEventListener("error", (event) => {
  const err = event.error || event;
  try {
    // Disabled for now, let's see if crossorigin helps
    // if (err?.message === "Script error.") {
    //   reportWarningToBackend({
    //     msg: "uncaught script error",
    //     err: serializeError(err),
    //   });
    // } else {
    reportErrorToBackend({
      msg: "uncaught error",
      err: serializeError(err),
    });
    // }
  } catch {
    // Ignore, don't want our unhandled error handler cause an unhandled error
  }
});

store.dispatch(versionActions.initialize((window as any).env.uiVersion));
store.dispatch(loadApiKeys());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
