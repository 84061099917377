import { useTranslation } from "react-i18next";
import { useL10n } from "../../../l10n";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";

type ActivityTimeEditDialogProps = {
  onClose: () => void;
  onDateSelected: (date: DateTime) => void;
  initialValue: DateTime;
  title: string;
  actionInProgress: boolean;
};

const ActivityTimeEditDialog = (props: ActivityTimeEditDialogProps) => {
  const { t } = useTranslation("stopPage");
  const { title, onClose, actionInProgress, onDateSelected } = props;
  const l10n = useL10n();

  const [date, setDate] = useState(props.initialValue);

  const onOkClick = () => {
    if (date) {
      onDateSelected(date);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <div>
            {t("arrivalDeparture.date")} {l10n.formatDate(date.toString())}
          </div>
          <TextField
            type="time"
            value={date?.toFormat("HH:mm")}
            onChange={(e) => {
              const time = DateTime.fromFormat(e.target.value, "HH:mm");
              setDate(
                date!.set({
                  hour: time.get("hour"),
                  minute: time.get("minute"),
                })
              );
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={actionInProgress} onClick={onClose}>
          {t("arrivalDeparture.cancel")}
        </Button>
        <Button disabled={actionInProgress} onClick={onOkClick}>
          {t("arrivalDeparture.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityTimeEditDialog;
