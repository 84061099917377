import { Stop } from "../../../ducks/app/session/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectStopCompletedDate } from "../../../ducks/app/session/selectors";
import { useTranslation } from "react-i18next";
import { pickup, dropoff } from "../../../ducks/app/session";
import LoadingButton from "@mui/lab/LoadingButton";
import { DateTime } from "luxon";
import ActivityTimeEditDialog from "./ActivityTimeEditDialog";
import EditIcon from "@mui/icons-material/Edit";
import { useActions } from "../../../hooks/useActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { useL10n } from "../../../l10n";

type StopCompletedProps = {
  sessionId: string;
  date: DateTime | null;
  stop: Stop;
  buttonText: string;
  editTitle: string;
  action: (input: {
    stopId: string;
    sessionId: string;
    cargoId: string;
    time?: DateTime;
  }) => Promise<PayloadAction<any>>;
};

const StopCompleted = (props: StopCompletedProps) => {
  const { sessionId, stop } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  const date = props.date;

  const onDateSelected = (date: DateTime) => {
    setActionInProgress(true);
    props
      .action({
        stopId: stop.id,
        sessionId,
        cargoId: stop.cargo_id,
        time: date!,
      })
      .then(() => setShowEditDialog(false))
      .finally(() => setActionInProgress(false));
  };

  const onButtonClick = () => {
    if (date) {
      setShowEditDialog(true);
    } else {
      setActionInProgress(true);
      props
        .action({
          stopId: stop.id,
          sessionId,
          cargoId: stop.cargo_id,
        })
        .finally(() => setActionInProgress(false));
    }
  };

  return (
    <>
      {showEditDialog && (
        <ActivityTimeEditDialog
          actionInProgress={actionInProgress}
          initialValue={props.date!}
          title={props.editTitle}
          onClose={() => setShowEditDialog(false)}
          onDateSelected={onDateSelected}
        />
      )}
      <LoadingButton
        loading={actionInProgress}
        size="large"
        variant="contained"
        onClick={onButtonClick}
        startIcon={date ? <EditIcon /> : null}
      >
        <span>{props.buttonText}</span>
      </LoadingButton>
    </>
  );
};

export const PickupDropoffButton = ({
  stop,
  sessionId,
}: {
  stop: Stop;
  sessionId: string;
}) => {
  const { t } = useTranslation(["translation", "stopPage"]);
  const date = useSelector(selectStopCompletedDate(stop.id));
  const actions = useActions({ pickup, dropoff });
  const l10n = useL10n();
  const isPickup = stop.type === "PICKUP";
  const buttonText = isPickup
    ? date
      ? `${t("Collected")}  ${date?.toFormat(
          "HH:mm"
        )} (${l10n.formatDate(date.toString())})`
      : t("Collect")
    : date
      ? `${t("Delivered")}  ${date?.toFormat(
          "HH:mm"
        )} (${l10n.formatDate(date.toString())})`
      : t("Deliver");

  return (
    <StopCompleted
      stop={stop}
      date={date}
      sessionId={sessionId}
      buttonText={buttonText}
      action={isPickup ? actions.pickup : actions.dropoff}
      editTitle={
        isPickup
          ? t("stopPage:stopCompleted.editPickup")
          : t("stopPage:stopCompleted.editDropoff")
      }
    />
  );
};
