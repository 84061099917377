import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import * as actions from "../../../ducks/app/session";
import { useAppDispatch } from "../../../redux-store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AddImage = ({
  sessionId,
  cargoId,
  stopId,
  added,
  imagesForStop,
}: {
  sessionId: string;
  cargoId: string;
  stopId: string;
  added: boolean;
  imagesForStop: number;
}) => {
  const { t } = useTranslation("stopPage");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      const image = files[0];
      const formData = new FormData();
      formData.append("cargoId", cargoId);
      formData.append("stopId", stopId);
      formData.append("documentType", "POD");
      formData.append("file", image, image.name);
      setLoading(true);
      dispatch(actions.uploadFile2({ sessionId, formData })).finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label>
          <LoadingButton
            loading={loading}
            variant="outlined"
            fullWidth
            size="large"
            component="span"
            endIcon={<CameraAltOutlinedIcon />}
          >
            {t("takePhoto")}
            <input
              capture="environment"
              style={{ display: "none" }}
              type="file"
              id="take-picture"
              accept="image/*"
              onChange={onChange}
            />
            {added && <CheckIcon />}
            <span>{imagesForStop ? `(${imagesForStop})` : ""}</span>
          </LoadingButton>
        </label>
      </form>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label>
          <LoadingButton
            loading={loading}
            variant="outlined"
            fullWidth
            size="large"
            component="span"
            endIcon={<CollectionsOutlinedIcon />}
          >
            <span>{t("addPhoto")}</span>
            <input
              style={{ display: "none" }}
              type="file"
              id="take-picture"
              accept="image/*"
              onChange={onChange}
            />
            {added && <CheckIcon />}
            <span>{imagesForStop ? `(${imagesForStop})` : ""}</span>
          </LoadingButton>
        </label>
      </form>
    </>
  );
};

export default AddImage;
