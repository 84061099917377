import { DateTime } from "luxon";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import { useL10n } from "../../../l10n";
import { useAppSelector } from "../../../redux-store";
import {
  driverArrivedAtDestination,
  driverDepartedFromDestination,
} from "../../../ducks/app/session";
import {
  selectDriverArrivedAtStop,
  selectDriverDepartedFromStop,
} from "../../../ducks/app/session/selectors";
import { useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTranslation } from "react-i18next";
import ActivityTimeEditDialog from "./ActivityTimeEditDialog";

type Stop = { id: string; cargo_id: string };

type StopDriverArrivedDepartedProps = {
  sessionId: string;
  buttonText: string;
  editTitle: string;
  stop: Stop;
  date: DateTime | null;
  type: "arrival" | "departure";
  action: (input: {
    stopId: string;
    sessionId: string;
    cargoId: string;
    time?: DateTime;
  }) => Promise<void>;
};

const StopDriverArrivedDeparted = (props: StopDriverArrivedDepartedProps) => {
  const { sessionId, stop } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  const date = props.date;

  const onDateSelected = (date: DateTime) => {
    setActionInProgress(true);
    props
      .action({
        stopId: stop.id,
        sessionId,
        cargoId: stop.cargo_id,
        time: date!,
      })
      .then(() => setShowEditDialog(false))
      .finally(() => setActionInProgress(false));
  };

  const onButtonClick = () => {
    if (date) {
      setShowEditDialog(true);
    } else {
      setActionInProgress(true);
      props
        .action({
          stopId: stop.id,
          sessionId,
          cargoId: stop.cargo_id,
        })
        .finally(() => setActionInProgress(false));
    }
  };

  return (
    <>
      {showEditDialog && (
        <ActivityTimeEditDialog
          actionInProgress={actionInProgress}
          initialValue={props.date!}
          title={props.editTitle}
          onClose={() => setShowEditDialog(false)}
          onDateSelected={onDateSelected}
        />
      )}
      <LoadingButton
        loading={actionInProgress}
        size="large"
        variant="contained"
        onClick={onButtonClick}
        startIcon={date ? <EditIcon /> : null}
      >
        <span>{props.buttonText}</span>
      </LoadingButton>
    </>
  );
};

type P = {
  sessionId: string;
  stop: Stop;
};

export const StopDriverArrived = (props: P) => {
  const { t } = useTranslation("stopPage");
  const date = useAppSelector(selectDriverArrivedAtStop(props.stop.id));
  const actions = useActions({ driverArrivedAtDestination });
  const l10n = useL10n();
  return (
    <StopDriverArrivedDeparted
      {...props}
      action={actions.driverArrivedAtDestination}
      date={date}
      type="arrival"
      buttonText={
        date
          ? `${t("arrivalDeparture.arrivedAt")}  ${date?.toFormat(
              "HH:mm"
            )} (${l10n.formatDate(date.toString())})`
          : `${t("arrivalDeparture.arrival")}`
      }
      editTitle={t("arrivalDeparture.editArrival")}
    />
  );
};

export const StopDriverDeparted = (props: P) => {
  const { t } = useTranslation("stopPage");
  const date = useAppSelector(selectDriverDepartedFromStop(props.stop.id));
  const actions = useActions({ driverDepartedFromDestination });
  const l10n = useL10n();
  return (
    <StopDriverArrivedDeparted
      {...props}
      action={actions.driverDepartedFromDestination}
      date={date}
      type="departure"
      buttonText={
        date
          ? `${t("arrivalDeparture.departedAt")} ${date?.toFormat(
              "HH:mm"
            )} (${l10n.formatDate(date.toString())})`
          : `${t("arrivalDeparture.departure")}`
      }
      editTitle={t("arrivalDeparture.editDeparture")}
    />
  );
};
