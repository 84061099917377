import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, TextField } from "@mui/material";
import { useState } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";
import { useAppDispatch } from "../../../../redux-store";

type EditDateDialogProps = {
  onClose: () => void;
  date: DateTime;
  cargoId: string;
  stopId: string;
};

const EditDateDialog = (props: EditDateDialogProps) => {
  const { cargoId, stopId, onClose } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [date, setDate] = useState<DateTime>(props.date);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(updateCargoStop({ cargoId, stopId, data: { date } }));
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.dateTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          type="date"
          margin="dense"
          value={date.toISODate()}
          onChange={(e) => {
            setDate(DateTime.fromISO(e.target.value));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={onClose}>
          {t("editDialogs.cancelButton")}
        </Button>
        <Button
          disabled={isSaving || date.equals(props.date)}
          onClick={onSaveButtonClick}
        >
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDateDialog;
