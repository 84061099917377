import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import PlaceSearch from "../../../AddNewCargoPage/PlaceSearch/PlaceSearch";
import { useState } from "react";
import {
  SearchOption,
  SearchResultSearchOption,
  ReverseGeocodeSearchOption,
} from "../../../AddNewCargoPage/PlaceSearch/SearchOption";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux-store";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";

type EditAddressDialogProps = {
  cargoId: string;
  stopId: string;
  onClose: () => void;
};

const EditAddressDialog = (props: EditAddressDialogProps) => {
  const { cargoId, stopId, onClose } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [searchLocation, setSearchLocation] = useState<
    SearchResultSearchOption | ReverseGeocodeSearchOption | null
  >(null);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      const lookupResult = await searchLocation!.value.data.lookup();
      await dispatch(
        updateCargoStop({
          cargoId,
          stopId,
          data: {
            place: {
              ...lookupResult,
              country: lookupResult.countryCode,
              position: {
                lat: lookupResult.coords.lat,
                lon: lookupResult.coords.lon,
              },
            },
          },
        })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const onPlaceChange = (newPlace: SearchOption | null) => {
    setSearchLocation(newPlace as any);
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.addressTitle")}</DialogTitle>
      <DialogContent>
        <PlaceSearch
          label={t("editDialogs.addressLabelPlaceholder")}
          location={searchLocation}
          onLocationChange={onPlaceChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={onClose}>
          {t("editDialogs.cancelButton")}
        </Button>
        <Button disabled={isSaving} onClick={onSaveButtonClick}>
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddressDialog;
