import { Alert, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddCargoSuccessPage = ({ sessionId }: { sessionId: string }) => {
  const { t } = useTranslation("translation");
  return (
    <>
      <Typography variant="h5" component="h1">
        {t("CargoAdded")}
      </Typography>

      <Alert severity="success" sx={{ my: "1rem" }}>
        <Typography variant="body1">{t("NewCargoAddedToRoute")}</Typography>
      </Alert>

      <Button
        fullWidth
        component={Link}
        variant="contained"
        to={`/sessions/${sessionId}`}
      >
        {t("Back")}
      </Button>
    </>
  );
};

export default AddCargoSuccessPage;
