import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { useState } from "react";
import { selectStopComments } from "../../ducks/app/session/selectors";
import { useSelector } from "react-redux";
import { Stop } from "../../ducks/app/session/types";
import * as actions from "../../ducks/app/session";
import { List, ListItem, ListItemText } from "@mui/material";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../redux-store";
import { useTranslation } from "react-i18next";

const AddCommentPage = (props: { sessionId: string; stop: Stop }) => {
  const { sessionId, stop } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("stopPage");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const comments = useSelector(selectStopComments(stop.id));
  const onCancel = () => {
    navigate(-1);
  };
  const onAddClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        actions.addComment({
          sessionId,
          comment,
          cargoId: stop.cargo_id,
          stopId: stop.id,
        })
      ).unwrap();
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography variant="h6" component="h2" pt={2}>
        {t("notes.heading")}
      </Typography>
      <List>
        {comments.map((c, i) => (
          <ListItem
            sx={{ px: 2 }}
            key={c.createdAt}
            disableGutters
            divider={i < comments.length - 1}
          >
            <ListItemText
              primary={c.comment}
              secondary={DateTime.fromISO(c.createdAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            />
          </ListItem>
        ))}
      </List>
      <form onSubmit={onAddClick}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              type="text"
              value={comment}
              label={t("notes.textFieldLabel")}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              <span>{t("notes.addButtonLabel")}</span>
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={onCancel}
            >
              {t("notes.backButtonLabel")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddCommentPage;
