import { DateTime } from "luxon";
import * as t from "io-ts";
import api, { createDecoder } from "../../api";
import { CargoLocation, addDropoffT } from "./types";
import { AppThunkAction, useAppDispatch } from "../../../redux-store";
import { getPosition } from "../../../helpers/position";
import { loadSession } from "../session";
import { selectCargo } from "../session/selectors";
import { useSelector } from "react-redux";

export type AddDropoffBody = {
  placeName: string;
  country: string | null;
  postcode: string | null;
  city: string | null;
  address: string | null;
  time: DateTime;
  lm: string;
  revision: string;
  position: { lon: number; lat: number };
  // driverPosition: { lon: number; lat: number; accuracy: number };
};

const addDropoff =
  ({
    sessionId,
    cargoId,
    body,
  }: {
    sessionId: string;
    cargoId: string;
    body: AddDropoffBody;
  }): AppThunkAction<Promise<{ stopId: string }>> =>
  async (dispatch) => {
    const driverPosition = await getPosition();
    const result = await api({ dispatch }).post({
      url: `/drivers-api/cargos/${cargoId}/dropoffs`,
      body: addDropoffT.encode({
        ...body,
        sessionId,
        driverPosition,
      }),

      decoder: createDecoder(t.strict({ stopId: t.string })),
    });
    await dispatch(loadSession(sessionId));
    return result;
  };

type AddDropoffInput = {
  lm: string;
  time: DateTime;
  dropoffLocation: CargoLocation;
};

export type AddDropoff = (
  input: AddDropoffInput
) => Promise<{ stopId: string }>;

export const useDropoffPage = (input: {
  sessionId: string;
  cargoId: string;
}) => {
  const dispatch = useAppDispatch();
  const cargo = useSelector(selectCargo(input.cargoId));
  return {
    cargo,
    addDropoff: async (
      dropoffInput: AddDropoffInput
    ): Promise<{ stopId: string }> => {
      const { lm, time } = dropoffInput;
      if (!cargo) {
        throw new Error("No cargo");
      }
      return await dispatch(
        addDropoff({
          ...input,
          body: {
            lm,
            time,
            ...dropoffInput.dropoffLocation,
            revision: cargo.revision,
          },
        })
      );
    },
  };
};
