import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerAppBar from "./Drawer";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSelector } from "react-redux";
import { selectCurrentSessionId } from "../ducks/app/session/selectors";
import { matchPath, Link, useNavigate } from "react-router-dom";

export default function AppTopBar(props: {
  darkMode: boolean;
  setDarkMode: (mode: boolean) => void;
}) {
  const { darkMode, setDarkMode } = props;
  const pathname = window.location.pathname;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentSessionId = useSelector(selectCurrentSessionId);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (pathname === "/") return null;

  const backButton =
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-note" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-weight" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-cubic-meters" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/waypoints/:waypointId" },
      pathname
    ) ||
    matchPath({ path: "sessions/:sessionId/stops/:stopId" }, pathname);

  const authPages =
    // matchPath({ path: "routes/login" }, pathname) ||
    matchPath({ path: "login" }, pathname);
  const exitPageButton = matchPath(
    { path: "sessions/:sessionId/cargos/add" },
    pathname
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!authPages && (
        <AppBar position="static">
          <Toolbar>
            {backButton && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => navigate(-1)}
              >
                <KeyboardArrowLeftRoundedIcon />
              </IconButton>
            )}
            {exitPageButton && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                component={Link}
                to={`/sessions/${currentSessionId}`}
              >
                <CloseOutlinedIcon />
              </IconButton>
            )}{" "}
            <Box sx={{ flexGrow: 1, alignSelf: "flex-end" }} />
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <DrawerAppBar
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
            />
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
}
