import { RootState } from "../../../redux-store";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { DateTime } from "luxon";
import { RouteCargoStop, RouteStop, Session } from "./types";
import { createSelector } from "@reduxjs/toolkit";
import { Dictionary } from "lodash";

export const selectStopIds = (id: string) => (state: RootState) => {
  const session = state.app.session.sessionStops[id];
  return session ? session.stopIds : [];
};

const selectStopsAndWaypoints = createSelector(
  (state: RootState) => state.app.session.currentSessionId,
  (state: RootState) => state.app.session.sessionStops,
  (currentSessionId, sessionStops) =>
    sessionStops[currentSessionId || ""]?.routeStops || []
);

export const selectCargosCreatedByDispatcher = (state: RootState) =>
  Object.values(state.app.session.cargos).filter((x) => !x.createdByDriver);

export const selectCargosCreatedByDriver = (state: RootState) =>
  Object.values(state.app.session.cargos)
    .filter((x) => x.createdByDriver)
    .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

export const selectCargo = (cargoId?: string) => (state: RootState) => {
  return (cargoId && state.app.session.cargos[cargoId]) || null;
};

export const selectClient = (clientId?: string) => (state: RootState) =>
  (clientId && state.app.session.clients[clientId]) || null;

export const selectCurrentSessionId = (state: RootState) =>
  state.app.session.currentSessionId;

export const selectStop = (stopId: string) => (state: RootState) =>
  state.app.session.stops[stopId];

export const selectCargoPickup = (cargoId: string) => (state: RootState) => {
  const cargoStops = state.app.session.routeStops
    .filter(isCargoStop)
    .filter((rcs) => rcs.stop.cargo_id === cargoId);
  return cargoStops.find((rs) => rs.stop.type === "PICKUP")?.stop;
};

export const selectWaypoint = (waypointId: string) => (state: RootState) =>
  state.app.session.routeStops.find(
    (s) => s.type === "WAYPOINT" && s.id === waypointId
  );

const innerSelectIsStopCompleted = (
  sessions: Dictionary<Session>,
  stopId: string
) => {
  return values(sessions).some((ses) => ses.stops[stopId]?.completedAt);
};

export const selectIsStopCompleted = (stopId: string) => (state: RootState) => {
  return innerSelectIsStopCompleted(state.app.session.sessions, stopId);
};

export const selectStopCompletedDate =
  (stopId: string) => (state: RootState) => {
    const val =
      values(state.app.session.sessions).find(
        (ses) => ses.stops[stopId]?.completedAt
      )?.stops[stopId]?.completedAt || null;
    return val ? DateTime.fromISO(val) : null;
  };

export const shouldConsiderWaypointAsCompleted = (
  routeStops: RouteStop[],
  index: number,
  sessions: Dictionary<Session>
): boolean => {
  const next = routeStops[index + 1];
  if (!next) {
    return false;
  }
  if (next.type === "WAYPOINT") {
    return shouldConsiderWaypointAsCompleted(routeStops, index + 1, sessions);
  }
  return innerSelectIsStopCompleted(sessions, next.stop.id);
};

export const selectArrivalDepartureButtonsEnabledForCustomer =
  (stopId: string) => (state: RootState) => {
    const stop = selectStop(stopId)(state);
    if (!stop) {
      return false;
    }
    const cargo = selectCargo(stop.cargo_id)(state);
    if (!cargo?.clientId) {
      return null;
    }
    const client = selectClient(cargo.clientId)(state);
    return client?.showArrivalDepartureToDriver || null;
  };

export const selectCubicMetersEnabledForCustomer =
  (stopId: string) => (state: RootState) => {
    const stop = selectStop(stopId)(state);
    if (!stop) {
      return false;
    }
    const cargo = selectCargo(stop.cargo_id)(state);
    if (!cargo?.clientId) {
      return null;
    }
    const client = selectClient(cargo.clientId)(state);
    return client?.showAddCubicMetersToDriver || null;
  };

export const selectShouldDisplayHideShowCompletedStopsButton = (
  state: RootState
) => {
  const sessionId = state.app.session.currentSessionId;
  if (!sessionId) {
    return false;
  }
  const allStops = Object.values(state.app.session.sessions).flatMap((s) =>
    Object.values(s.stops)
  );

  const cargos = Object.values(state.app.session.cargos);
  if (cargos.some((c) => !c.createdByDriver && c.driverCanAddDropoffs)) {
    return false;
  }

  for (const stop of allStops) {
    if (stop.completedAt && !selectStopCreatedByDriver(stop.id)(state)) {
      return true;
    }
  }
  return false;
};

export const selectStopCreatedByDriver =
  (stopId: string) => (state: RootState) => {
    const cargo = selectCargo(selectStop(stopId)(state)?.cargo_id)(state);
    return cargo?.createdByDriver;
  };

export const selectStopsAndWaypointsNumber = createSelector(
  selectStopsAndWaypoints,
  (stopsAndWaypoints) => stopsAndWaypoints.length
);

export const selectStopsAndWaypointsFilteredByShouldShowCompleted =
  createSelector(
    selectStopsAndWaypoints,
    selectShouldDisplayHideShowCompletedStopsButton,
    (state: RootState) => state.app.session.sessions,
    (state: RootState) => state.app.session.cargos,
    (state: RootState) => state.app.session.showCompletedStops,
    (allRouteStops, shouldShowButton, sessions, cargos, showCompletedStops) => {
      if (!showCompletedStops && shouldShowButton) {
        allRouteStops = allRouteStops.filter((stop, i) => {
          if (stop.type === "WAYPOINT") {
            return !shouldConsiderWaypointAsCompleted(
              allRouteStops,
              i,
              sessions
            );
          }
          if (cargos[stop.stop.cargo_id]?.createdByDriver) {
            return true;
          }
          return !innerSelectIsStopCompleted(sessions, stop.stop.id);
        });
      }
      const allRouteStopsCompleted = allRouteStops.filter((stop) => {
        if (stop.type === "WAYPOINT") {
          return shouldConsiderWaypointAsCompleted(
            allRouteStops,
            allRouteStops.indexOf(stop),
            sessions
          );
        }
        return innerSelectIsStopCompleted(sessions, stop.stop.id);
      });
      return [
        ...allRouteStopsCompleted,
        ...allRouteStops.filter(
          (stop) => !allRouteStopsCompleted.includes(stop)
        ),
      ];
    }
  );

const getLatestStopWithPropFromSessions = (
  state: RootState,
  stopId: string,
  prop: "arrivedAt" | "departedAt"
) => {
  const sessionsWithStop = values(state.app.session.sessions).filter(
    (ses) => ses.stops[stopId]
  );
  const propArray = sessionsWithStop
    .map((s) => s.stops[stopId][prop])
    .filter((x) => x);
  return propArray.length
    ? DateTime.fromISO(propArray[propArray.length - 1]!)
    : null;
};

export const selectDriverArrivedAtStop =
  (stopId: string) => (state: RootState) => {
    return getLatestStopWithPropFromSessions(state, stopId, "arrivedAt");
  };

export const selectDriverDepartedFromStop =
  (stopId: string) => (state: RootState) => {
    return getLatestStopWithPropFromSessions(state, stopId, "departedAt");
  };

export const selectShowCompletedStops = (state: RootState) =>
  state.app.session.showCompletedStops;

export const selectIsStarted =
  (sessionId: string | null) => (state: RootState) =>
    Object.values(state.app.session.sessions).some((x) => x.started);
// (sessionId && state.app.session.sessions[sessionId]?.started) || false;

export const selectIsStopped =
  (sessionId: string | null) => (state: RootState) =>
    Object.values(state.app.session.sessions).some((x) => x.stopped);
// (sessionId && state.app.session.sessions[sessionId]?.stopped) || false;

export const selectCargoSize = (cargoId: string) => (state: RootState) =>
  state.app.session.cargos[cargoId]?.size || null;

export const selectCargoAttachments = (cargoId: string) => (state: RootState) =>
  state.app.session.attachmentsByCargoId[cargoId];

const isCargoStop = (stop: RouteStop): stop is RouteCargoStop =>
  stop.type === "CARGO_STOP";

export const selectRelatedStopsFromCargo =
  (stopId: string, cargoId: string) => (state: RootState) => {
    const stops = state.app.session.routeStops
      .filter(isCargoStop)
      .filter((rs) => rs.stop.cargo_id === cargoId && rs.stop.id !== stopId)
      .map((rs) => rs.stop);
    return {
      pickups: stops.filter((s) => s.type === "PICKUP"),
      dropoffs: stops.filter((s) => s.type === "DROPOFF"),
    };
  };

export const selectCargoHasDocument =
  (cargoId: string, documentType: string) => (state: RootState) =>
    (state.app.session.attachmentsByCargoId[cargoId] || [])
      .map((id) => state.app.session.attachments[id])
      .some((x) => x.documentType === documentType);

// TODO: this selector and the one above could be simplified with useSelector
export const selectCargoDocumentsNumberForStop =
  (cargoId: string, stopId: string, documentType: string) =>
  (state: RootState) => {
    return (state.app.session.attachmentsByCargoId[cargoId] || [])
      .map((id) => state.app.session.attachments[id])
      .filter((x) => x.stopId === stopId && x.documentType === documentType)
      .length;
  };

export const selectStopComments = (stopId: string) => (state: RootState) => {
  const comments = values(state.app.session.sessions)
    .map((x) => x.stops[stopId])
    .filter((x) => x)
    .flatMap((x) => x.comments);
  return orderBy(uniqBy(comments, "createdAt"), "createdAt");
};

export const selectMeasuredStopWeight =
  (stopId: string) => (state: RootState) => {
    const weights = values(state.app.session.sessions)
      .map((x) => x.stops[stopId])
      .filter((x) => x)
      .flatMap((x) => (x.weight ? [x.weight] : []));
    return weights.length ? weights[0] : null;
  };

export const selectMeasuredStopCubicMeters =
  (stopId: string) => (state: RootState) => {
    const cubicMeters = values(state.app.session.sessions)
      .map((x) => x.stops[stopId])
      .filter((x) => x)
      .flatMap((x) => (x.cubicMeters ? [x.cubicMeters] : []));
    return cubicMeters.length ? cubicMeters[0] : null;
  };

export const selectStopHasComment = (stopId: string) => (state: RootState) =>
  !!selectStopComments(stopId)(state).length;

export const selectStopClient = (stopId: string) => (state: RootState) => {
  const stop = selectStop(stopId)(state);
  const cargo = stop && selectCargo(stop.cargo_id)(state);
  return cargo?.clientId ? selectClient(cargo.clientId)(state) : null;
};

export const selectCargoHasInvoiceRef =
  (cargoId: string) => (state: RootState) => {
    return selectCargo(cargoId)(state)?.hasInvoiceRef || false;
  };

export const selectStopDocuments = (stopId: string) => (state: RootState) =>
  state.app.session.stopsDocuments[stopId] || [];
