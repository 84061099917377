import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { Box, TextField, Typography, Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useDropoffPage, AddDropoff } from "../../ducks/app/add-cargo/hooks";
import PlaceSearch from "../AddNewCargoPage/PlaceSearch/PlaceSearch";
import * as hooks from "./hooks";
import CargoInfo from "./StopPage/CargoInfo";
import { Cargo } from "../../ducks/app/session/types";
import { useParams } from "react-router";
import StopDateTimeFields from "../../components/StopDateTimeFields";
import { selectHideLoadmeters } from "../../ducks/data/driver-app-settings/selectors";
import { useAppSelector } from "../../redux-store";
import { SearchOption } from "../AddNewCargoPage/PlaceSearch/SearchOption";
import { selectCargoPickup } from "../../ducks/app/session/selectors";

const DropoffFormInner = ({
  cargo,
  sessionId,
  addDropoff,
}: {
  cargo: Cargo;
  sessionId: string;
  addDropoff: AddDropoff;
}) => {
  const { t } = useTranslation("addDropoffPage");
  const [lm, setLm] = useState(cargo.undeliveredLm);
  const navigate = hooks.useNavigate();
  const now = useMemo(() => DateTime.now(), []);
  const [dropoffTime, setDropoffTime] = useState(now);
  const [loading, setLoading] = useState(false);
  const [dropoffLocation, setDropoffLocation] = useState<SearchOption | null>(
    null
  );
  const hideLoadmeters = useAppSelector(selectHideLoadmeters);
  const cargoPickup = useAppSelector(selectCargoPickup(cargo.id));
  const [pickupDropoffTimeError, setPickupDropoffTimeError] = useState(false);

  const onCancel = () => {
    navigate(-1);
  };

  const onClick = async (e: React.SyntheticEvent) => {
    setPickupDropoffTimeError(false);
    e.preventDefault();
    if (!dropoffLocation || !dropoffTime) {
      return;
    }
    if (cargoPickup?.time && cargoPickup.time > dropoffTime.toFormat("HH:mm")) {
      setPickupDropoffTimeError(true);
      return;
    }
    setPickupDropoffTimeError(false);
    // TODO: time validation with pickup
    try {
      setLoading(true);
      if (!dropoffLocation) {
        return;
      }
      const loc = await (async () => {
        switch (dropoffLocation.type) {
          case "REVERSE_GEOCODE_OPTION":
          case "SEARCH_RESULT":
            const lookupData = await dropoffLocation.value.data.lookup();
            return {
              googlePlaceId: dropoffLocation.value.data.id,
              placeName: lookupData.placeName || "",
              country: lookupData.countryCode,
              position: lookupData.coords,
              address: lookupData.address,
              city: lookupData.city,
              postcode: lookupData.postcode,
            };
          case "SAVED_LOCATION": {
            return {
              placeName: dropoffLocation.value.data.place.placeName || "",
              country: dropoffLocation.value.data.place.country,
              position: dropoffLocation.value.data.place.coord,
              address: dropoffLocation.value.data.place.address,
              city: dropoffLocation.value.data.place.city,
              postcode: dropoffLocation.value.data.place.postcode,
            };
          }
        }
      })();
      const { stopId } = await addDropoff({
        lm,
        time: dropoffTime,
        dropoffLocation: loc,
      });
      navigate(`/sessions/${sessionId}/stops/${stopId}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={onClick}>
        {!hideLoadmeters && (
          <TextField
            label={t("loadmeterField")}
            margin="dense"
            fullWidth
            value={lm}
            required
            InputProps={{
              componentsProps: {
                input: {
                  inputMode: "decimal",
                },
              },
            }}
            onChange={(e) => setLm(e.target.value)}
          />
        )}
        <StopDateTimeFields
          dateFieldLabel={t("dropoffDateFieldLabel")}
          timeFieldLabel={t("dropoffTimeFieldLabel")}
          value={dropoffTime}
          timeError={pickupDropoffTimeError}
          onChange={setDropoffTime}
        />
        <PlaceSearch
          label={t("dropoffPlaceFieldLabel")}
          location={dropoffLocation}
          onLocationChange={setDropoffLocation}
        />
        <Stack spacing={2} mt={2}>
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            loading={loading}
          >
            {t("saveStopButtonText")}
          </LoadingButton>
          <Button variant="outlined" size="large" fullWidth onClick={onCancel}>
            {t("cancelButtonLabel")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export const AddDropoffForm = ({
  cargoId,
  sessionId,
}: {
  sessionId: string;
  cargoId: string;
}) => {
  const dropoffPage = useDropoffPage({ sessionId, cargoId });
  const cargo = dropoffPage.cargo;
  if (!cargo) {
    return null;
  }
  return (
    <DropoffFormInner
      cargo={cargo}
      sessionId={sessionId}
      addDropoff={dropoffPage.addDropoff}
    />
  );
};

const AddDropoffPage = ({ sessionId }: { sessionId: string }) => {
  const { t } = useTranslation("addDropoffPage");
  const { cargoId } = useParams();
  if (!cargoId) {
    return null;
  }
  return (
    <>
      <Typography component="h1" variant="h5">
        {t("pageHeading")}
      </Typography>
      <CargoInfo cargoId={cargoId} stopId={null} />
      <AddDropoffForm sessionId={sessionId} cargoId={cargoId} />
    </>
  );
};

export default AddDropoffPage;
