import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import AuthPage from "./pages/Auth/LoginPage";
import SessionsPage from "./pages/SessionsPage";
import { ThemeProvider } from "@mui/material/styles";
import { theme, darkTheme } from "./styles/theme";
import Container from "@mui/material/Container";
import AppTopBar from "./layouts/TopBar";
import Footer from "./layouts/Footer";
import { Provider } from "react-redux";
import { store } from "./redux-store";
import RouteLoginPage from "./pages/Auth/SelectTrailerPage";
import I18nReduxLink from "./i18n/ReduxLink";
import NotebookPage from "./pages/NotebookPage/NotebookPage";
import * as featureActions from "./ducks/features";

function App() {
  const [darkMode, setDarkMode] = React.useState(false);

  store.dispatch(featureActions.initialize());

  document.addEventListener("visibilitychange", () => {
    store.dispatch(featureActions.initialize());
  });

  return (
    <Provider store={store}>
      <I18nReduxLink />
      <ThemeProvider theme={darkMode ? darkTheme : theme}>
        <CssBaseline />
        <Router>
          <Grid container direction="column">
            <Grid item>
              <AppTopBar darkMode={darkMode} setDarkMode={setDarkMode} />
            </Grid>
            <Grid item>
              <Container component="main" maxWidth="sm" sx={{ mt: "3rem" }}>
                <Routes>
                  <Route path="/login" element={<AuthPage />} />
                  <Route
                    path="*"
                    element={
                      <>
                        <Routes>
                          <Route
                            path="/routes/login"
                            element={<RouteLoginPage me={1 as any} />}
                          />
                          <Route
                            path="/routes/login-completed-trips"
                            element={<RouteLoginPage me={1 as any} showCompletedTrips />}
                          />
                          <Route
                            path="/sessions/:sessionId/*"
                            element={<SessionsPage />}
                          />
                        </Routes>
                      </>
                    }
                  />
                  <Route path="/driver-notebook" element={<NotebookPage />} />
                  <Route path="/" element={<Navigate to="/routes/login" />} />
                </Routes>
              </Container>
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Grid>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
