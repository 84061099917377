import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  selectCargoHasDocument,
  selectMeasuredStopWeight,
  selectStopComments,
  selectStopClient,
  selectStopDocuments,
  selectCargoDocumentsNumberForStop,
  selectMeasuredStopCubicMeters,
  selectCargoHasInvoiceRef,
} from "../../../ducks/app/session/selectors";
import CargoInfo from "./CargoInfo";
import { useSelector } from "react-redux";
import { Stop } from "../../../ducks/app/session/types";
import AddImage from "./AddImage";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PersonIcon from "@mui/icons-material/Person";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import {
  Link as MUILink,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CargoDimensions from "./CargoDimensions";
import RouteAddress from "../RouteAddress";
import { useAppDispatch } from "../../../redux-store";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import { FilePresentOutlined, SellOutlined } from "@mui/icons-material";
import { createStopDocumentTypes } from "dora-contracts";
import * as iots from "io-ts";
import { loadStopDocuments } from "../../../ducks/app/session";
import useFeature from "../../../hooks/useFeature";
import { useL10n } from "../../../l10n";
import {
  StopDriverArrived,
  StopDriverDeparted,
} from "./StopDriverArrivedDeparted";
import { selectHideLoadmeters } from "../../../ducks/data/driver-app-settings/selectors";
import {
  selectMe,
  selectShouldShowArrivalDepartureButtons,
  selectShouldShowCubicMeters,
  selectShouldShowEditCargoButtons,
} from "../../../ducks/auth/selectors";
import * as featureActions from "../../../ducks/features";
import "./StopPage.scss";
import EditAddressDialog from "./edit-dialogs/EditAddressDialog";
import EditDateDialog from "./edit-dialogs/EditDateDialog";
import EditTimeDialog from "./edit-dialogs/EditTimeDialog";
import EditLoadmetersDialog from "./edit-dialogs/EditLoadmetersDialog";
import EditClientDialog from "./edit-dialogs/EditClientDialog";
import { DateTime } from "luxon";
import { PickupDropoffButton } from "./StopCompleted";
import StopsFromSameCargo from "./StopsFromSameCargo";

const { stopDocumentT } = createStopDocumentTypes({ dateTime: iots.string });
export type Document = iots.TypeOf<typeof stopDocumentT>;

const StopPage = ({ sessionId, stop }: { sessionId: string; stop: Stop }) => {
  const l10n = useL10n();
  const podAdded = useSelector(selectCargoHasDocument(stop.cargo_id, "POD"));
  const podNumberForStop = useSelector(
    selectCargoDocumentsNumberForStop(stop.cargo_id, stop.id, "POD")
  );
  const currentWeight = useSelector(selectMeasuredStopWeight(stop.id));
  const currentCubicMeters = useSelector(
    selectMeasuredStopCubicMeters(stop.id)
  );
  const comments = useSelector(selectStopComments(stop.id));
  const client = useSelector(selectStopClient(stop.id));
  const hasNote = comments.length > 0;
  const currentInvoiceRef = useSelector(
    selectCargoHasInvoiceRef(stop.cargo_id)
  );

  // driver edit cargo
  const shouldShowEditCargoButtons = useSelector(
    selectShouldShowEditCargoButtons
  );
  const [shouldShowEditAddressDialog, setShouldShowEditAddressDialog] =
    useState(false);
  const [shouldShowEditLoadmetersDialog, setShouldShowEditLoadmetersDialog] =
    useState(false);
  const [shouldShowEditDateDialog, setShouldShowEditDateDialog] =
    useState(false);
  const [shouldShowEditTimeDialog, setShouldShowEditTimeDialog] =
    useState(false);
  const [shouldShowEditClientDialog, setShouldShowEditClientDialog] =
    useState(false);
  const onEditAddressClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShouldShowEditAddressDialog(true);
  };

  const areDocumentsEnabled = useFeature("stops-documents");

  const dispatch = useAppDispatch();
  const files = useSelector(selectStopDocuments(stop.id));

  const hideLoadmeters = useSelector(selectHideLoadmeters);

  useEffect(() => {
    dispatch(loadStopDocuments({ stopId: stop.id }));
    dispatch(featureActions.initialize());
  }, [dispatch, stop.id]);

  const shouldShowArrivalDepartureButtons = useSelector(
    selectShouldShowArrivalDepartureButtons(stop.id)
  );

  const addInvoiceRefFeatureEnabled = useFeature("driver-add-ref-button");
  const addInvoiceRefEnabled = useSelector(selectMe)?.canAddInvoiceRef;
  const cubicMetersEnabled = useSelector(selectShouldShowCubicMeters(stop.id));

  const { t } = useTranslation(["translation", "stopPage", "types"]);
  const googleMapsQuery = encodeURIComponent(
    `${stop.coord.lat},${stop.coord.lon}`
  );

  return (
    <div className="stop-page">
      {shouldShowEditClientDialog && (
        <EditClientDialog
          cargoId={stop.cargo_id}
          stopId={stop.id}
          clientId={client?.id || null}
          onClose={() => setShouldShowEditClientDialog(false)}
        />
      )}
      {shouldShowEditAddressDialog && (
        <EditAddressDialog
          cargoId={stop.cargo_id}
          stopId={stop.id}
          onClose={() => setShouldShowEditAddressDialog(false)}
        />
      )}
      {shouldShowEditDateDialog && (
        <EditDateDialog
          cargoId={stop.cargo_id}
          stopId={stop.id}
          onClose={() => setShouldShowEditDateDialog(false)}
          date={DateTime.fromFormat(stop.date!, "yyyy-MM-dd")}
        />
      )}
      {shouldShowEditTimeDialog && (
        <EditTimeDialog
          cargoId={stop.cargo_id}
          stopId={stop.id}
          onClose={() => setShouldShowEditTimeDialog(false)}
          time={stop.time}
        />
      )}
      {shouldShowEditLoadmetersDialog && (
        <EditLoadmetersDialog
          cargoId={stop.cargo_id}
          stopId={stop.id}
          onClose={() => setShouldShowEditLoadmetersDialog(false)}
          loadmeters={stop.lm}
        />
      )}
      <CargoInfo
        cargoId={stop.cargo_id}
        stopId={stop.id}
        shouldShowDriverEditButtons={shouldShowEditCargoButtons}
      />
      <Stack>
        <List>
          <div className="w-full">
            <div className="flex justify-between align-center">
              <ListItem disablePadding>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <span>Client:&nbsp;</span>
                {client?.name}
              </ListItem>
              {shouldShowEditCargoButtons && (
                <Button onClick={() => setShouldShowEditClientDialog(true)}>
                  <EditOutlinedIcon />
                </Button>
              )}
            </div>
          </div>

          <ListItem disablePadding>
            <ListItemButton
              disableRipple
              sx={{ px: 0 }}
              aria-label={t("stopPage:showOnMap")}
              component="a"
              href={`https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemIcon>
                <LocationOnOutlinedIcon />
              </ListItemIcon>
              {stop.place && (
                <div className="stop-info-row">
                  <RouteAddress address={stop.place} />
                  {shouldShowEditCargoButtons && (
                    <Button onClick={onEditAddressClick}>
                      <EditOutlinedIcon />
                    </Button>
                  )}
                </div>
              )}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disableRipple disableTouchRipple sx={{ px: 0 }}>
              <ListItemIcon>
                <TodayOutlinedIcon />
              </ListItemIcon>
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <div>{stop.date ? l10n.formatDate(stop.date) : null}</div>
                  {shouldShowEditCargoButtons && (
                    <Button onClick={() => setShouldShowEditDateDialog(true)}>
                      <EditOutlinedIcon />
                      {/* {t("stopPage:editButton")} */}
                    </Button>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <>{stop.time && ` ${stop.time}`}</>
                    <>{stop.fixTime && ` (${t("translation:Fix")})`}</>
                  </div>
                  {shouldShowEditCargoButtons && (
                    <Button onClick={() => setShouldShowEditTimeDialog(true)}>
                      {/* {t("stopPage:editButton")} */}
                      <EditOutlinedIcon />
                    </Button>
                  )}
                </div>
              </div>
            </ListItemButton>
          </ListItem>
          {(stop.ref || stop.contact) && (
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple sx={{ px: 0 }}>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <>{stop.ref && `${t("translation:Reference")}: ${stop.ref}`}</>
                <>{stop.ref && stop.contact && <br />}</>
                <>
                  {stop.contact &&
                    `${t("translation:Contact")}: ${stop.contact}`}
                </>
              </ListItemButton>
            </ListItem>
          )}
          {stop.phone && (
            <ListItem disablePadding>
              <ListItemButton
                disableRipple
                disableTouchRipple
                sx={{ px: 0 }}
                component="a"
                href={`tel:${stop.phone}`}
                rel="noreferrer"
              >
                <ListItemIcon>
                  <PhoneInTalkOutlinedIcon />
                </ListItemIcon>
                <div className="stop-info-row">
                  <div>{stop.phone}</div>
                </div>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton disableRipple disableTouchRipple sx={{ px: 0 }}>
              <ListItemIcon>
                <SquareFootOutlinedIcon />
              </ListItemIcon>
              <div className="stop-info-row">
                <div className="w-full">
                  <div>
                    {stop.colli && (
                      <div>
                        {t("translation:colli")}: {stop.colli}
                      </div>
                    )}
                    {!hideLoadmeters && (
                      <div className="flex items-center justify-between">
                        <div className="flex-grow">
                          {t("translation:Loadmeter", {
                            count: +stop.lm,
                          })}
                        </div>
                        {shouldShowEditCargoButtons && (
                          <Button
                            onClick={() =>
                              setShouldShowEditLoadmetersDialog(true)
                            }
                          >
                            {/* {t("stopPage:editButton")} */}
                            <EditOutlinedIcon />
                          </Button>
                        )}
                      </div>
                    )}
                    {stop.pieces &&
                      stop.pieces.map((p, i) => (
                        <div key={i}>
                          {p.quantity} x {t(`types:unitTypes.unit_${p.unit}`)}
                        </div>
                      ))}
                  </div>
                  <div>
                    <CargoDimensions type={stop.type} size={stop} />
                  </div>
                  {stop.weight && <div>{stop.weight} kg</div>}
                  {stop.cubicMeters && <div>{stop.cubicMeters} m³</div>}
                  {stop.temperature && <div>{stop.temperature} °C</div>}
                </div>
              </div>
            </ListItemButton>
          </ListItem>
          {stop.tag && (
            <ListItem disablePadding>
              <ListItemButton disableRipple disableTouchRipple sx={{ px: 0 }}>
                <ListItemIcon>
                  <SellOutlined />
                </ListItemIcon>
                <div>
                  {t("translation:cmrTag")}: {stop.tag}
                </div>
              </ListItemButton>
            </ListItem>
          )}
          {areDocumentsEnabled && (
            <>
              {files.length !== 0 && (
                <ListItem disablePadding>
                  <ListItemButton
                    disableRipple
                    disableTouchRipple
                    sx={{ px: 0 }}
                  >
                    {files.length && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {files.map((file) => (
                          <div key={file.id}>
                            <ListItemIcon>
                              <FilePresentOutlined />
                            </ListItemIcon>

                            <MUILink
                              href={file.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {file.name}
                            </MUILink>
                            <br />
                            <br />
                          </div>
                        ))}
                      </div>
                    )}
                  </ListItemButton>
                </ListItem>
              )}
            </>
          )}
        </List>
      </Stack>

      <StopsFromSameCargo className="mt-3" stop={stop} />

      <Stack spacing={2} pt={4} mb={4}>
        <AddImage
          sessionId={sessionId}
          cargoId={stop.cargo_id}
          stopId={stop.id}
          added={podAdded}
          imagesForStop={podNumberForStop}
        />
        <Button component={Link} to="add-note" variant="outlined" size="large">
          {t("stopPage:addNote")}
          {hasNote && <CheckIcon />}
        </Button>
        <Button
          component={Link}
          to="add-weight"
          variant="outlined"
          size="large"
        >
          {t("stopPage:addWeight")}
          {currentWeight && <CheckIcon />}
        </Button>
        {cubicMetersEnabled && (
          <Button
            component={Link}
            to="add-cubic-meters"
            variant="outlined"
            size="large"
          >
            {t("stopPage:addCubicMeters")}
            {currentCubicMeters && <CheckIcon />}
          </Button>
        )}
        {addInvoiceRefFeatureEnabled && addInvoiceRefEnabled && (
          <Button component={Link} to="add-ref" variant="outlined" size="large">
            {t("stopPage:addInvoiceRef")}
            {currentInvoiceRef && <CheckIcon />}
          </Button>
        )}
        {shouldShowArrivalDepartureButtons && (
          <>
            <StopDriverArrived sessionId={sessionId} stop={stop} />
            <StopDriverDeparted sessionId={sessionId} stop={stop} />
          </>
        )}
        <PickupDropoffButton stop={stop} sessionId={sessionId || ""} />
      </Stack>
    </div>
  );
};

export default StopPage;
