import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux-store";
import { updateCargoStop } from "../../../../ducks/app/update-cargo";

type EditDescriptionDialogProps = {
  cargoId: string;
  stopId: string;
  desc: string | null;
  onClose: () => void;
};

const EditDescriptionDialog = (props: EditDescriptionDialogProps) => {
  const { cargoId, stopId, onClose } = props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [desc, setDesc] = useState<string | null>(props.desc);

  const onSaveButtonClick = async () => {
    try {
      setIsSaving(true);
      await dispatch(
        updateCargoStop({ cargoId, stopId, data: { description: desc || "" } })
      );
      onClose();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  return (
    <Dialog fullWidth open onClose={() => !isSaving && onClose()}>
      <DialogTitle>{t("editDialogs.descriptionTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={desc || ""}
          onChange={(e) => setDesc(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={onClose}>
          {t("editDialogs.cancelButton")}
        </Button>
        <Button disabled={isSaving || !desc || props.desc === desc} onClick={onSaveButtonClick}>
          {t("editDialogs.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDescriptionDialog;
